<template>
  <div class="mtop-100 font-sfns">
    <b-container>
      <b-row class="mbottom-100">
        <b-col>
          <label>Number</label>
          <b-form-input v-model="number"></b-form-input>
        </b-col>
        <b-col>
          <label>Amount</label>
          <b-form-input v-model="amount"></b-form-input>
        </b-col>
      </b-row>
      <h4 class="card-title">Top Transactions</h4>
        <b-table class="mbottom-100" :items="topTransactions" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <!--
              <template v-slot:cell(action)="data">
                <span><button @click="viewPage(data.item.id)" class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button></span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template> -->
        </b-table>
     </b-container>
  </div>
</template>


<script>
import _ from 'lodash'
export default {
  title: "Monthly Transactions",
  data() {
    return {
      loaded: false,
      number: 10,
      amount: 2000,
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      currentPage: 1,
      perPage: 0,
      sortable: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'plot_no', sortable: true},
        { key: 'invoice_no', sortable: true},
        { key: 'en_date', sortable: true},
        { key: 'hij_date', sortable: true},
        { key: 'meter_sq', sortable: true},
        { key: 'amount', sortable: true},
        { key: 'city', sortable: true},
        { key: 'district', sortable: true},
        { key: 'classification', sortable: true},
        { key: 'price_per_sq_meter', sortable: true},
      ],
    }
  },
  components: {
  },
  computed: {
    topTransactions() {
      return this.$store.state.transaction.top_transactions
    },
  },
  watch: {
    number() {
      if (this.number != "") {
        this.filterTransactions()
      }
    },
    amount() {
      if (this.amount != "") {
        this.filterTransactions()
      }
    },
  },
  methods: {
    fetchTopTransactions() {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("transaction/topTransactions", {meta: {top: this.number, amount: this.amount, sub_classification: 1}})
      .then(() => {
        this.loaded = true
        loader.hide()
      })
    },
    filterTransactions: _.debounce(function() {
      this.fetchTopTransactions()
    }, 2000)
  },
  created() {
    this.fetchTopTransactions()
  }

}
</script>

<style lang="scss">
</style>
